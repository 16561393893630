<template>
  <v-combobox
    v-model.trim="model"
    :items="data"
    label="Customer"
    :loading="status === 'pending'"
    persistent-hint
    :readonly="readonly"
    :required="required"
  />
</template>

<script lang="ts" setup>
const model = defineModel<string>({ default: undefined });

defineProps<{
  required?: boolean;
  readonly?: boolean;
}>();

const { data, status } = useLazyFetch("/api/proposals/customers", {
  default: () => [],
});
</script>
